#footer {
    border-top: 1px solid #8B8B8B;
    background-color: white;
}

footer {
    display: flex;
    justify-content: space-between;
}

.containerFooter {
    display: flex;
    flex-direction: column;
    min-height: 20vh;
    justify-content: center;
    align-items: center;
    width: 33%;
    border-right: 1px solid #8B8B8B;
}

.containerFooter:last-child {
    border-right: unset;
}

.containerFooter p {
    color: #1C5029;
    margin-bottom: 0;
}

.suscribeFooter {
    padding: 50px 0;
    width: 85%;
    margin: auto;
}

.suscribeFooter h3 {
    color: #848484;
    margin-bottom: 30px !important;
}

.suscribeFooter h3 span {
    color: #1C5029;
}

.container-suscribe-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    border: 2px solid #37994F;
    border-radius: 5px;
}

.suscribeFooter input:not(input[type=submit]) {
    width: calc(90% - 40px);
    background-color: transparent;
    border: transparent;
    text-indent: 20px;
    padding: 9px 0;
}

.suscribeFooter input:focus,
.suscribeFooter input:focus-visible {
    border: transparent;
}

.suscribeFooter input::placeholder {
    color: #1C5029;
    font-size: 13px;
    font-family: "MarkPro Bold";
}

.container-suscribe-footer input[type=submit] {
    background-color: transparent;
    border: transparent;
    color: white;
    font-size: 13px;
    font-family: "MarkPro Bold";
    background: #37994F;
    padding: 10px 20px;
    cursor: pointer;
}

.suscribeFooter p {
    margin-bottom: 10px !important;
}

.redes a {
    margin-right: 12px;
}

.containerFooter h4 {
    margin-bottom: 30px !important;
}

.infoFooter {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.infoFooter img {
    margin-right: 8px;
}

.ubicationFooter p {
    margin-bottom: 0 !important;
}

.ubicationFooter a {
    margin-left: 6px;
    color: #858585;
    font-family: "MarkPro Regular"
}

.ubicationFooter a:hover {
    color: #858585;
}

.subFooter {
    border-top: 1px solid #8B8B8B;
    padding: 10px 2.5%;
    display: flex;
    justify-content: space-between;
}

.subFooter span {
    color: #464646;
    font-size: 10px;
    font-family: "MarkPro Regular";
}


@media only screen and (max-width: 1100px) {
    footer {
        flex-direction: column;
    }

    .containerFooter {
        width: 100%;
    }

    .containerFooter:nth-of-type(1),
    .containerFooter:nth-of-type(2) {
        border-bottom: 1px solid #848484;
    }

    .primaryFooter {
        margin-bottom: 20px;
    }

    .primaryFooter .contactFooter {
        margin-bottom: 0;
    }

    .suscribeFooter {
        width: 90%;
    }

    .primaryFooter,
    .secondaryFooter {
        width: 90%;
        padding: 5% 0 0;
    }

    .subFooter {
        flex-direction: column;
        width: 100%;
        padding: 10px 5%;
    }
}