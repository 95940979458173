#nosotros{
    position: relative;
}
.circleFondo{
    position: absolute;
    z-index: -1;
}
.circleCuarto{
    top: -150px;
    left: 0;
    transform: rotate(240deg);
    z-index: -1;
    width: 230px;
}
.circleGreen{
    bottom: 0px;
    left: 80px;
    width: 300px;
}
.semiCircleGreen{
    left: -300px;
    width: 500px;
}
.semiCircleLineGreen{
    right: 0;
    bottom: 600px;
}
#nosotros p{
    color: #464646;
    margin-bottom: 20px !important;
}
.section-one-nosotros, .section-two-nosotros, .section-three-nosotros{
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 80px 0;
}
.section-one-nosotros{
    padding: 120px 0 80px !important;
}
.section-one-nosotros h1{
    color: #464646 !important;
    font-size: 74px;
    line-height: 85px;
    font-family: 'MarkPro Regular';
    letter-spacing: 0;
}

.section-one-nosotros h1 span{
    font-family: 'MarkPro Bold';
}
.section-two-nosotros{
    position: relative;
}
.section-two-nosotros iframe{
    position: relative;
    z-index: 10;
}
.section-two-nosotros div{
    width: 40%;
    margin-left: 40px;
}
.section-three-nosotros{
    width: 60%;
    flex-direction: column;
    align-items: flex-start;
}
.section-three-nosotros h2{
    font-size: 34px;
    line-height: 43px;
    margin-bottom: 10px !important;
}
.section-two-nosotros h3{
    margin-bottom: 10px !important;
}
.section-three-nosotros h3{
    font-size: 18px;
    line-height: 24px;
}
.section-three-nosotros+img{
    border-radius: 20px;
    width: 80%;
    margin-left: 10%;
    margin-bottom: 80px;
    z-index: 10;
    position: relative;
}

.mobileElement{
    display: none;
}

@media only screen and (max-width: 1100px) {
    .mobileElement{
        display: block;
    }
    .desktopElement{
        display: none;
    }
    .circleCuarto{
        width: 30%;
    }
    .circleGreen{
        width: 150px;
        left: 0;
    }
    .section-one-nosotros, .section-two-nosotros, .section-three-nosotros{
        flex-direction: column;
        width: 90%;
        padding: 40px 0 !important;
    }
    .section-one-nosotros img{
        width: 100%;
    }
    .section-one-nosotros h1{
        font-size: 35px;
        line-height: 40px;
        margin-top: 20px !important;
        text-align: right;
        margin-right: 25px;
    }
    .section-one-nosotros h1 br{
        display: none;
    }
    .section-two-nosotros{
        padding: 0;
    }
    .section-two-nosotros div {
        width: 100%;
        margin-left: 0;
        margin-bottom: 30px;
    }
    .section-two-nosotros div h3{
        width: 80%;
    }
    .section-two-nosotros div h3, .section-three-nosotros h2, .section-three-nosotros h3{
        font-size: 20px;
        line-height: 23px;
        font-family: 'MarkPro Regular';
        text-align: initial;
    }
    .section-two-nosotros iframe{
        width: 100%;
        height: 220px;
    }
    .section-three-nosotros+img{
        width: 90%;
        margin-left: 5%;
    }
    .semiCircleLineGreen{
        display: none;
    }
}