#noticias {
  width: 85%;
  margin: 120px auto 80px;
}
.containerNoticias {
  position: relative;
}
.semiCircleGreenNoticia {
  left: 0;
  bottom: 100px;
  z-index: -1;
}

.headerNoticias {
  display: flex;
  justify-content: space-between;
  margin: 2.5%;
}

.containerNoticias {
  display: flex;
  flex-wrap: wrap;
}

.containerNoticias #card {
  margin: 2.5%;
}

.ant-select {
  cursor: pointer;
}
.ant-select-selection-placeholder,
.ant-select-arrow,
.ant-select-selection-item {
  color: #37994f;
  font-family: "MarkPro Regular";
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: transparent;
  background: transparent;
  width: calc(100% - 22px);
}
