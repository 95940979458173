#home {
  position: relative;
}
/* Logo Home */
.headerLanding {
  width: 100%;
  background: white;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoHeaderHome{
  width: 300px;
}
.circlePortadaHome {
  position: absolute;
  top: 0;
  z-index: 100;
}
/* Portada Home */
.portadaHome {
  height: calc(75vh - 70px);
  width: 100%;
  overflow: hidden;
  position: relative;
}
.portadaHome .slick-slider,
.portadaHome .slick-slide div,
.portadaHome .slick-track,
.portadaHome .slick-list {
  height: 100%;
}
.arrowSliderLeft {
  position: absolute;
  z-index: 100;
  bottom: calc(50% - 27px);
  left: 5%;
  background: transparent;
  border: transparent;
  cursor: pointer;
}
.arrowSliderRight {
  position: absolute;
  z-index: 100;
  bottom: calc(50% - 27px);
  right: 5%;
  background: transparent;
  border: transparent;
  cursor: pointer;
}
.portadaHome img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.portadaText{
  object-fit: contain !important;
}
/* Seleccion de vista Home */
.portadaSelectionSite {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectionSite {
  height: calc(25vh - 70px);
  width: calc(50% - 40px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 20px 20px 40px 20px;
  display: flex;
}
.primary {
  background-image: url("../../img/backgroudnPrimary.png");
}
.secondary {
  background-image: url("../../img/backgroudnSecondary.png");
}
.titlePrimary {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
}
/* About us Home */
.aboutUs {
  width: 90%;
  padding: 60px 5% 90px;
  background-image: url("../../img/backgroundNosotrosHome.png");
  background-repeat: no-repeat;
  background-position: right;
}
.containerAboutUs {
  display: flex;
  justify-content: space-between;
  margin: 70px 0;
}
.card-aboutUs {
  background: #ffffff;
  box-shadow: 0px 16px 65px rgba(22, 34, 51, 0.08);
  border-radius: 15px;
  width: calc(23% - 60px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 280px;
}
.card-aboutUs img {
  position: absolute;
  top: -50px;
}
.card-aboutUs .button-green {
  position: absolute;
  bottom: -20px;
}
.card-aboutUs h3 {
  text-align: center;
}
.card-aboutUs p {
  color: #464646;
  padding-top: 20px;
  font-size: 13px;
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
/* Certficate*/
.certificate {
  padding: 40px 0;
}
.certificate h4 {
  text-align: center;
  margin-bottom: 30px !important;
}
.certificateSlider img {
  width: 80% !important;
  height: 150px;
  object-fit: contain;
  margin: auto;
}
.certificateSlider .slick-track, .certificateSlider .slick-track .slick-slide  div{
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Select View */
.selectView {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  padding: 60px 2.5%;
  background-image: url("../../img/backgroundSelectView.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.containerSelectView {
  aspect-ratio: 1/1;
  width: calc(49% - 60px);
  padding: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  justify-content: space-between;
}
.containerSelectView h3 {
  color: white;
}
/*
.news {
  background-image: url("../../img/news.png");
}
.activities {
  background-image: url("../../img/activities.png");
}*/
@media screen and (min-width: 1100px) and (max-width:1500px) {
.containerAboutUs{
  flex-wrap: wrap;
  justify-content: center;
}
.card-aboutUs{
  width: calc(35% - 60px);
  margin: 5%;
}
}

@media only screen and (max-width: 1100px) {
  .portadaHome{
    height: 40vh;
  }
  .circlePortadaHome{
    display: none;
  }
  .arrowSliderRight,
  .arrowSliderLeft {
    width: 30px;
    height: 30px;
    bottom: calc(50% - 15px);
  }
  .portadaSelectionSite {
    flex-direction: column;
  }
  .selectionSite {
    width: calc(100% - 20px);
    height: calc(30vh - 100px);
    padding: 20px 10px;
  }
  .selectionSite a{
    font-size: 12px;
  }
  .titlePrimary,
  .titlePrimary {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .titlePrimary p {
    margin-bottom: 0;
  }
  .titlePrimary h1 {
    margin-bottom: 10px !important;
    font-size: 25px;
    line-height: 25px;
  }
  .aboutUs {
    padding: 60px 5% 30px;
  }
  .containerAboutUs {
    flex-direction: column;
    margin: 50px 0;
  }
  .card-aboutUs {
    width: calc(100% - 60px) !important;
    margin-bottom: 100px;
  }
  .card-aboutUs:last-child {
    margin-bottom: 0;
  }
  .card-aboutUs img {
    top: -30px;
    left: 5%;
    width: 70px;
  }
  .selectView{
    flex-direction: column;
  }
  .containerSelectView{
    height: 50vh;
    width: calc(100% - 60px);
    flex-direction: column;
    align-items: center;
    justify-content: end;
  }
  .activities{
    margin-bottom: 10px;
  }
  .containerSelectView h3{
    margin-bottom: 10px !important;
    font-size: 25px;
    line-height: 25px;
  }
}
