#index {
  position: relative;
}
#backgroundPrimary{
  background: #FAFAFA;
}
.circleHome {
  position: absolute;
  top: 1650px;
  z-index: -1;
  left: 0;
}
.circleHomeLines {
  position: absolute;
  right: 0;
  top: 60%;
  z-index: -1;
}
.triangleHome {
  position: absolute;
  z-index: -10;
  top: 80%;
}
.portadaIndex {
  width: 100%;
  height: auto;
}
.portadaIndex img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}
.noticiasIndex {
  padding: 50px 0 40px;
}
.noticiasIndex h3 {
  text-align: center;
}
.sliderNoticiasIndex {
  width: 100%;
  margin-left: auto;
  margin-top: 40px;
  position: relative;
}
.backgroundSliderIndex {
  width: 2.5%;
  height: 100%;
  position: absolute;
  background-color: white;
  z-index: 10;
  left: 0;
}
.sliderNoticiasIndex
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  div
  #card {
  width: 95%;
  margin: auto;
}
.objetivo {
  width: 70%;
  max-width: 1000px;
  margin: 90px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.objetivo h3 {
  color: #848484;
  margin-top: 30px !important
}
.objetivo h3 span{
  color: rgba(55, 153, 79, 1);
}
/* Background y colores depenendiendo la vista */
#indexSecondary .noticiasIndex {
  background-image: url("../../img/circleDarkGreenLeft.png");
  background-repeat: no-repeat;
  background-position: 0% 90%;
  background-size: 10%;
}
#indexSecondary .subContainerIndex {
  background-image: url("../../img/circleDarkGreenLinesRight.png");
  background-repeat: no-repeat;
  background-position: 100% 60%;
}
#indexPrimary .noticiasIndex {
  background-image: url("../../img/circleLightGreenLeft.png");
  background-repeat: no-repeat;
  background-position: 0% 90%;
  background-size: 10%;
}
#indexPrimary .subContainerIndex {
  background-image: url("../../img/circleLightGreenLinesRight.png");
  background-repeat: no-repeat;
  background-position: 100% 60%;
}
.index {
  background-image: url("../../img/triangleBackground.png");
  background-repeat: no-repeat;
  background-position: 0% 100%;
}
.subContainerIndex #sliderGallery {
  margin-bottom: 70px;
  margin-top: 70px;
}

@media only screen and (max-width: 1100px) {
  .portadaIndex {
    height: calc(55vh - 80px);
    border-radius: 0 0 50% 50%/0 0 20% 20%;
    transform: scaleX(1.1);
  }
  .noticiasIndex {
    padding: 40px 0 40px;
  }
  .backgroundSliderIndex {
    display: none;
  }
  .objetivo {
    width: 90%;
    margin: 30px auto;
  }
  .objetivo h3 {
    font-size: 20px;
    line-height: 24px;
    font-family: "MarkPro Bold";
  }
  .circleHomeLines {
    width: 30%;
  }
  .circleHome {
    width: 30%;
    top: 35%;
  }
}
