#contact {
  width: 80%;
  max-width: 1200px;
  margin: 220px auto 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#contact img {
  width: 40%;
  margin-right: 120px;
}

#contact form {
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 400px;
}

#contact h3{
    font-family: 'MarkPro Regular';
    margin-bottom: 30px !important;
}

#contact form input,
#contact form textarea {
  margin-bottom: 20px;
  text-indent: 10px;
  padding: 10px 0;
  border: 1px solid #1c5029;
  border-radius: 10px;
}

#contact form textarea{
    padding: 10px;
    text-indent: 0;
    height: 100px;
}

#contact form input::placeholder,
#contact form textarea::placeholder {
  font-family: "MarkPro Regular";
  color: rgba(99, 99, 99, 0.5);
}

.button-contact {
  font-family: "MarkPro Regular";
  font-size: 15px;
  background-color: #195023;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: unset;
  padding: 10px 0;
  border: 1px solid #195023;
  transition: 0.5s all ease-in-out;
}

.button-contact:hover {
  color: #195023;
  background: transparent;
}

@media only screen and (max-width: 1100px) {
  #contact{
    flex-direction: column;
    margin: 40px auto;
  }
  #contact img{
    width: 90%;
    margin: 0 auto 40px;
  }
  #contact form{
    width: 100%;
  }
  #contact h3{
    font-size: 30px;
  }
}
