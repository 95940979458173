#noticia {
  margin: 140px auto 80px;
}

.containerNoticia,
.ultimasNoticias {
  max-width: 1100px;
  margin: auto;
}

.containerImgNoticia {
  width: 100%;
  height: 500px;
  border-radius: 30px;
  overflow: hidden;
}

.containerImgNoticia img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.headerNoticia {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 20px;
}

.headerNoticia h1 {
  font-family: 'MarkPro Bold';
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0;
  color: #000000 !important;
  margin-bottom: 0 !important;
}

.bodyNoticia {
  margin-top: 40px;
  width: 80%;
}
.bodyNoticia p {
  color: rgba(70, 70, 70, 1);
}

.ultimasNoticias {
  margin-top: 80px;
}
.ultimasNoticias #card{
  width: 90%;
}
.ultimasNoticias h3 {
  text-align: center;
}
.ultimasNoticiasContainer {
  margin-top: 50px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.ultimasNoticiasContainer .slick-slider {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  #noticia {
    width: 90%;
    margin: 30px auto;
  }
  .containerNoticias {
    flex-direction: column;
  }
  .headerNoticia{
    flex-direction: column;
    align-items:flex-start;
  }
  .headerNoticia h1{
    margin-bottom: 10px !important;
  }
  .containerNoticias #card {
    width: 100%;
    margin: auto auto 20px;
  }
  .headerNoticias h3{
    margin-bottom: 10px !important;
  }
  .bodyNoticia{
    width: 100%;
    margin: auto;
  }
}

@media screen and (min-width: 601px) and (max-width: 1100px) {
  .containerNoticias #card {
    width: 44%;
  }
}
