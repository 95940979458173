#galeria {
  width: 85%;
  margin: 120px auto 80px;
}

.containerGaleria {
  display: flex;
  flex-wrap: wrap;
}
.containerImgGaleria {
  width: 48%;
  border-radius: 30px;
  margin: 1%;
  overflow: hidden;
  height: auto;
}

.containerImgGaleria img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1/1;
}

@media only screen and (max-width: 820px) {
  #galeria {
    width: 90%;
    margin: 30px auto;
  }
  .headerNoticias {
    flex-direction: column;
    margin-bottom: 30px;
  }
  .headerNoticias h3 {
    font-size: 25px;
    line-height: 32px;
  }
  .ant-select {
    width: 100% !important;
    border: 1px solid rgb(28, 80, 41) !important;
  }
  .containerGaleria {
    flex-direction: column;
  }
  .containerImgGaleria {
    width: 100%;
    margin-bottom: 15px;
  }
}
