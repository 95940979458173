#team{
    width: 90%;
    margin: auto;
    overflow: hidden;
    padding-bottom: 90px;
}
#team h4{
    text-align: center;
    margin-bottom: 130px !important;
}
.teamCard {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  width: calc(90% - 80px) !important;
  background: #ffffff;
  box-shadow: 0px 10px 10px rgba(22, 34, 31, 0.08);
  border-radius: 24px;
  position: relative;
  height: 200px;
  padding: 40px;
  margin: auto;
}
.bodyTeamCard{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.bodyTeamCard p{
    color: #464646;
    text-align: center;
}
.bodyTeamCard p:nth-of-type(2){
    margin-bottom: 30px !important; 
}
.containerImgCard{
    position: absolute;
    top: -80px;
    padding: 10px;
    background: radial-gradient(circle, rgba(28,80,41,0.46262254901960786) 51%, rgba(255,255,255,0.19371498599439774) 74%);
    backdrop-filter: blur(18.8432px);
    border-radius: 100px;
    border: 2px solid rgba(28,80,41,0.1);
    object-fit: cover;
    overflow: visible;
}

 #team .slick-list{
    overflow: visible;
 }
.teamCard img{
    width: 130px;
    height: 130px;   
    border-radius: 100px;
    object-fit: cover;
    object-position: center;
}
/* Dots */
.slick-dots li{
    width: 10px;
    height: 10px;
    top: 30px;
}
.slick-dots li button:before{
    color: transparent !important;
    width: 10px;
    height: 10px;
    background: #1C5029;
    backdrop-filter: blur(12.5656px);
    border-radius: 50px;
}
@media only screen and (max-width: 1100px) {
    #team{
        width: 100%;
    }
}