* {
  margin: 0;
  padding: 0;
  box-sizing: unset !important;
}
.App{
  width: 100%;
  overflow: hidden;
  position: relative;
}

/* Fonts */
@font-face {
  font-family: "MarkPro Bold";
  src: url("../fonts/MarkPro/MarkPro-Bold.eot");
  src: url("../fonts/MarkPro/MarkPro-Bold.svg#LeSS") format("svg"),
    url("../fonts/MarkPro/MarkPro-Bold.ttf") format("truetype"),
    url("../fonts/MarkPro/MarkPro-Bold.woff") format("woff"),
    url("../fonts/MarkPro/MarkPro-Bold.eot?#iefix") format("embedded-opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "MarkPro Regular";
  src: url("../fonts/MarkPro/MarkPro.eot");
  src: url("../fonts/MarkPro/MarkPro.svg#LeSS") format("svg"),
    url("../fonts/MarkPro/MarkPro.ttf") format("truetype"),
    url("../fonts/MarkPro/MarkPro.woff") format("woff"),
    url("../fonts/MarkPro/MarkPro.eot?#iefix") format("embedded-opentype");
  font-weight: bold;
  font-style: normal;
}

h1, h2, h3, h4, h5, p{
  margin: 0 !important;
}

h1 {
  font-family: "MarkPro Bold";
  color: white !important;
  font-size: 50px;
  line-height: 50px;
  letter-spacing: 0.075em;
  text-transform: uppercase;
}

h2 {
  font-family: "MarkPro Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 56px;
  color: #000000;
  text-align: center;
}

h3 {
  font-family: "MarkPro Bold";
  color: #464646;
  font-size: 35px;
  line-height: 35px;
}

h4 {
  font-family: "MarkPro Bold";
  color: #195023;
  font-size: 25px;
  line-height: 32px;
}

h5 {
  font-family: "MarkPro Bold";
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  text-align: center;
  color: #464646;
}

p {
  font-family: "MarkPro Regular";
  color: white;
  font-size: 15px;
  line-height: 18px;
}

a{
  cursor: pointer;
  text-decoration: unset;
}

.button-white {
  font-family: "MarkPro Bold";
  font-size: 15px;
  background-color: white;
  color: #195023;
  border-radius: 80px;
  cursor: pointer;
  text-decoration: unset;
  padding: 10px 20px;
  border: 1px solid white;
  transition: 0.5s all ease-in-out;
}

.button-white:hover {
  color: white;
  background: transparent;
}

.button-green {
  font-family: "MarkPro Bold";
  font-size: 15px;
  background-color: #195023;
  color: white;
  border-radius: 80px;
  cursor: pointer;
  text-decoration: unset;
  padding: 10px 20px;
  border: 1px solid #195023;
  transition: 0.5s all ease-in-out;
}

.button-green:hover {
  color: #195023;
  background: transparent;
}
.button-news {
  color: #37994f;
  text-decoration: unset;
  padding-bottom: 1px;
  border-bottom: 1px solid #37994f;
  display: flex;
  font-family: "MarkPro Bold";
  font-size: 11px;
  width: fit-content;
  cursor: pointer;
  margin-top: 20px;
}
.button-news img {
  margin-left: 10px;
}
.button-news:hover{
color: #37994f;
}
.button-team-card {
  font-family: "MarkPro Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: #37994f;
  text-decoration: unset;
  border: 1px solid #37994f;
  padding: 10px 25px;
  border-radius: 10px;
  text-align:center;
  transition: .5s all ease-in-out;
}
.button-team-card:hover{
  color: white;
  background-color: #37994f;
}


@media only screen and (max-width: 1100px) {
  h2{
    font-size: 25px;
    line-height: 25px;
  }
  .button-white, .button-green{
    padding: 5px 20px;
  }

}

