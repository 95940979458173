#viewActivities,
#proyectosInterdiciplinarios {
  padding: 1.5% 0 2.5%;
  position: relative;
  margin: 120px auto 0;
}
.circleActivities {
  position: absolute;
  right: -10%;
  z-index: -1;
  top: 20%;
  width: 366px;
}
.triangleActivities {
  position: absolute;
  left: -30%;
  z-index: -1;
  top: 40%;
}
.portadaActivities {
  overflow: hidden;
  margin: 0 2.5%;
  width: calc(100% - 5%);
  position: relative;
  border-radius: 40px;
  height: 60vh;
}

.portadaActivities img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.portadaActivities h1 {
  font-family: "MarkPro Regular";
  font-weight: 400;
  font-size: 25px;
  line-height: 25px;
  color: #ffffff !important;
  letter-spacing: 0;
  position: absolute;
  z-index: 5;
  bottom: 20px;
  left: 40px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(7.54952px);
  border-radius: 10px;
  padding: 5px 20px;
}

.bodyActivities {
  padding: 50px 0;
  width: 60%;
  max-width: 1000px;
  margin: auto;
}
.bodyActivities div p {
  color: #464646;
  margin-bottom: 10px !important;
}
.bodyActivities div p b{
  font-family: 'MarkPro Bold';
}
.bodyActivities div p:last-child{
  margin-bottom: 0 !important;
}
.imagenSliderActivities {
  border-radius: 40px;
  width: 95% !important;
  margin: auto;
  overflow: hidden;
  aspect-ratio: 1/1;
}
.imagenSliderActivities img {
  width: 100%;
}

/* Arte secundaria */
#arteSecondary {
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  margin: 90px auto;
}
.imagenPortadaArte {
  width: 40%;
  margin-right: 90px;
}
#arteSecondary div {
  width: 50%;
}
#arteSecondary div h1 {
  font-family: "MarkPro Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  color: #000000 !important;
  letter-spacing: 0;
  text-transform: capitalize;
  margin: 20px 0!important;
}
#arteSecondary div h1 span {
  font-family: "MarkPro Bold";
}
#arteSecondary div p {
  color: #464646;
}

/* Ingles Secundaria */
#InglesSecondary {
  margin-top: 50px;
}

#InglesSecondary .bodyActivities h2 {
  font-family: "MarkPro Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 40px !important;
  line-height: 160%;
  color: #464646;
  margin: 50px 0 20px;
  text-align: inherit;
  margin-top: 30px !important;
}

#InglesSecondary p{
  margin-top: 10px !important;
}

.cambridgeImage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  padding: 30px 15px;
  background-color: #f6f6f6;
}

.cambridgeImage p {
  width: 50%;
  margin: 0;
}

.cambridgeImage p span {
  font-family: "MarkPro Bold";
}

.tableEnglish,
.table {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tableEnglish thead,
.table thead {
  color: white;
  background: #1c5029;
  border: transparent;
}

.tableEnglish thead tr,
.table thead tr {
  font-family: "MarkPro Bold";
}

.tableEnglish tr,
.table tr {
  display: flex;
  justify-content: space-between;
  font-family: "MarkPro Regular";
  padding: 15px;
}

.tableEnglish tr td, .tableEnglish thead th{
  width: 16%;
  text-align: inherit;
}


.tableEnglish tbody tr:nth-child(2n),
.table tbody tr:nth-child(2n) {
  background-color: #f6f6f6;
}

.tableEnglish tbody tr:last-child{
  font-family: "MarkPro Bold";
  color: rgba(55, 153, 79, 1);
}

.table tbody tr:first-child, .table tbody tr:first-child td p{
  font-family: "MarkPro Bold";
  background: rgba(55, 153, 79, 1);
  color: white;
}

.table tbody tr:first-child td:last-child p{
  font-family: "MarkPro Regular";
}

.table tr td:last-child a{
  color: rgba(55, 153, 79, 1);
  border-bottom: 1px solid rgba(55, 153, 79, 1);
}

.table tbody tr a {
  color: #464646;
  border-bottom: 1px solid #464646;
}

/* Proyectos interdiciplinarios */

.table {
  width: 100%;
  margin: 30px auto 0;
}

#proyectosInterdiciplinarios .sliderActivities {
  margin-top: 90px;
}

@media only screen and (max-width: 1100px) {
  .portadaActivities {
    border-radius: 15px;
  }
  #viewActivities,
  #proyectosInterdiciplinarios {
    padding: 3.5% 0 2.5%;
    margin: 20px auto 0;
  }
  .circleActivities {
    width: 30vw;
    top: -50px;
  }
  .portadaActivities h1 {
    left: 15px;
    bottom: 10px;
    font-size: 15px;
  }
  .bodyActivities {
    padding: 40px 0;
    width: 90%;
  }
  .imagenSliderActivities {
    height: 300px;
  }
  .table{
    width: 97%;
  }
  .table tbody tr td:nth-of-type(2){
    display: none;
  }
  .tableEnglish tbody tr{
    padding: 15px 10px;
  }
  .tableEnglish tbody tr, .table td, .table td p{
    font-size: 11px !important; 
  }
}
