/*.headerDummy{
    height: 80px;
}*/
#header {
  background: #ffffff;
  backdrop-filter: blur(7px);
  filter: drop-shadow(0px 4px 26px rgba(0, 0, 0, 0.11));
  height: 80px;
  position: fixed;
  width: 100%;
  z-index: 999;
}
header {
  width: 95%;
  height: 80px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header img{
    width: 250px;
    margin-right: 40px;
}
header a,  nav.mobileElement a {
  font-family: "MarkPro Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4a4a4a;
  text-decoration: none;
  margin-right: 35px;
  text-transform: uppercase;
}
/* header a:last-child,  nav.mobileElement a:last-child{
    margin-right: 0;
    font-family: "MarkPro Bold";
    color: #195023;
}
*/
header a:hover,  nav.mobileElement a:hover{
    font-family: "MarkPro Bold";
    color: #4a4a4a;
} 


@media only screen and (min-width: 1200px) and (max-width: 1500px)  {
  header a, nav.mobileElement a{
    margin-right: 10px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 1200px) {
  nav.desktopElement{
    display: none;
  }
  nav.mobileElement, .burgerMenu.mobileElement{
    display: flex;
  }
  .headerDummy, #header, header{
    height: 70px;
  }
  header{
    width: 95%;
  }
  header img {
    width: 200px;
  }

  nav.mobileElement{
    width: calc(100% - 2.5%);
    background:white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-left: 2.5%;
    padding-top: 20px;
    position: absolute;
    top: 70px;
    left: 100%;
    transition: .8s all ease-in-out;
  }

  .open+nav.mobileElement{
    left: 0;
  }

  nav.mobileElement a{
    margin-bottom: 20px;
  }

  .burgerMenu {
    width: 25px;
    height  : 14px;
    cursor  : pointer;
    position: relative;
    background-color: transparent;
    border: unset;
  }
  
  .burgerMenu span {
    background-color: rgba(74, 74, 74, 1);
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    transition: all 0.3s ease;
    border-radius: 2px;
  }
  
  .burgerMenu span:first-child {
    top: 0;
  }
  
  .burgerMenu span:nth-child(2) {
    top: 7px;
  }
  
  .burgerMenu span:last-child {
    top: 13px;
  }
  
  .open span:nth-child(2) {
    opacity: 0;
  }
  
  .open span:first-child,
  .open span:last-child {
    top: 6px;
    background-color: rgba(74, 74, 74, 1);
  }
  
  .open span:first-child {
    transform: rotate(45deg);
  }
  
  .open span:last-child {
    transform: rotate(-45deg);
  }
  
}