.message {
    z-index: 999;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.messageContainer {
    width: fit-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    border-radius: 8px;
}

.messageContainer img {
    width: 90% !important;
    margin: 0 auto !important;
}

.messageContainer h4 {
    color: black;
    font-weight: 500;
    text-align: center;
    font-size: 19px;
    margin: 20px auto !important;
}

.messageContainer button {
    color: #16151E;
    background: #47FFBD;
    border-radius: 5px;
    border: 1px solid #47FFBD;
    font-weight: 900;
    padding: 5px 0;
    width: 50%;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    transition: .5s all ease-in-out;
}

.messageContainer button:hover {
    color: white;
    background: transparent;
}