#programa {
  position: relative;
  margin: 80px auto 0;
}

.section-one-programa {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: calc(100vh - 80px);
  position: relative;
}

.circleProgramBackground {
  position: absolute;
  margin: 0 !important;
}

.circleProgramBackground:first-child {
  right: 0;
  top: -50px;
}

.circleProgramBackground:last-child {
  left: 0;
  bottom: 0;
  z-index: -1;
}

.containerPortadaPrograma {
  object-fit: cover;
  overflow: hidden;
  border-radius: 20px;
  margin-right: 30px;
  width: 40%;
  max-width: 550px;
}

.imagenPrograma {
  width: 100%;
}

.infoPrograma {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
}

.infoPrograma h1 {
  letter-spacing: 0;
  text-transform: capitalize;
  font-family: "MarkPro Regular";
  margin-bottom: 30px !important;
}

.infoPrograma h1,
.infoPrograma p {
  color: #464646 !important;
}

.section-two-programa {
  background-color: #f6f6f6;
  padding: 40px 0;
}

.metodologyContainer {
  width: 70%;
  margin: auto;
  max-width: 800px;
}

.metodologyContainer h2 {
  font-family: "MarkPro Bold";
  font-size: 34px;
  line-height: 43px;
  color: #464646;
  text-align: inherit;
  margin-bottom: 20px !important;
}

.metodologyContainer p {
  color: black;
}

.optionOneMetodolgy,
.optionTwoMetodolgy {
  margin: 30px auto;
  width: 100%;
}

.metodologyButtons {
  display: flex;
  justify-content: space-between;
}

.metodologyButtons button {
  width: 48%;
  padding: 10px 0;
  border-radius: 5px;
  font-family: "MarkPro Regular";
  font-size: 21px;
  line-height: 21px;
  background: transparent;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
}

.buttonSecondary {
  color: #1c5029;
  border: 1px solid #1c5029;
}

.buttonSecondary:hover,
.buttonSecondary.selected {
  color: white;
  background: #1c5029;
}

.buttonPrimary {
  color: #37994f;
  border: 1px solid #37994f;
}

.buttonPrimary:hover,
.buttonPrimary.selected {
  color: white;
  background: #37994f;
}

@media only screen and (max-width: 1100px) {
  #programa {
    margin: 0;
  }

  .circleProgramBackground {
    z-index: -1;
    width: 50%;
  }

  .infoPrograma h1,
  .metodologyContainer h2 {
    font-size: 22px;
    line-height: 28px;
  }

  .section-one-programa {
    flex-direction: column;
    height: fit-content;
    padding: 50px 0;
  }

  .containerPortadaPrograma {
    margin-right: 0;
    width: 90%;
    margin: auto;
  }

  .imagenPrograma {
    width: 100%;
    margin: auto;
  }

  .infoPrograma {
    width: 90%;
    margin: 30px auto 0 auto;
  }

  .infoPrograma img {
    width: 60px;
  }

  .circleProgramBackground:last-child {
    width: 30%;
  }

  .metodologyContainer {
    width: 90%;
  }

  .metodologyButtons {
    flex-direction: column;
  }

  .metodologyButtons button {
    width: 100%;
  }

  .metodologyButtons button:first-child {
    margin-bottom: 15px;
  }

  .infoPrograma h1 {
    margin-bottom: 10px !important;
  }
}