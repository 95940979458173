#card {
  border-radius: 5%;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  width: 28%;
  overflow: hidden;
  transition: .5s all ease-in-out;
}
#card:hover{
  transform: scale(0.9);
}
.portadaCard {
  width: 100%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  height: 250px;
  overflow: hidden;
}
.portadaCard img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bodyCard{
  margin-top: 20px;
  padding: 0 20px 20px 20px
}
.dateCard {
  font-family: "MarkPro Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: #464646;
  text-transform: uppercase;
}
.bodyCard h4 {
  font-size: 20px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 10px;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.bodyCard p {
  font-size: 13px;
  line-height: 18px;
  color: #464646;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
