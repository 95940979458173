#popUpAbout {
  background: linear-gradient(
    179.46deg,
    rgba(60, 60, 60, 0.16) 0.47%,
    rgba(255, 255, 255, 0) 93.18%
  );
  backdrop-filter: blur(6px);
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 100;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}
.popup {
  width: calc(50% - 100px);
  background: white;
  box-shadow: 0px 16px 65px rgba(22, 34, 51, 0.08);
  border-radius: 15px;
  padding: 50px 50px;
  position: relative;
}
.iconoPopup {
  position: absolute;
  top: -40px;
  left: 20px;
}
.closePopUp {
  position: absolute;
  background: transparent;
  border: transparent;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.closePopUp img {
  position: unset !important;
}
.popup h2,
.popup p {
  text-align: left;
  color: rgba(70, 70, 70, 1);
}
.popup p {
  margin-top: 20px;
  text-align: left !important;
  padding-top: 0 !important;
  display: inline !important;
}

/*OPEN POPUP HOME */
.openPopUpVision + #popUpAbout {
  display: flex;
}
.closePopUp + #popUpAbout {
  display: none;
}
.linksPopup {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.linksPopup a {
  color: white !important;
  background: #195023;
  padding: 7px 15px;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
  transition: .5s all ease-in-out;
  border: 1px solid #195023;
  margin-bottom: 10px;
}
.linksPopup a:hover {
  background: transparent;
  color: #195023 !important;
}
.card-aboutUs:not(.politicas) .linksPopup{
  display: none;
}
@media only screen and (max-width: 1100px) {
  .popup {
    width: calc(90% - 30px);
    padding: 60px 15px 15px;
  }
  .closePopUp img {
    height: 15px;
    width: 15px;
  }
}

@media only screen and (max-height: 700px) {
  .popup {
    width: calc(90% - 100px);
  }
}
