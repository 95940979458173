.headerCantina {
  display: flex;
  justify-content: space-between;
  margin: 2.5%;
}

.containerCantina {
  display: flex;
  flex-wrap: wrap;
}

.containerCantina #card {
  margin: 2.5%;
}


.containerCantina #card .button-news{
    display: none;
}